<template>
  <v-container>
    <div class="contenedor">
      <v-row class="justify-center">
        <v-col cols="12" md="4">
          <v-card>
            <v-card-text style="padding-bottom: 0">
              <div class="content-lotti">
                <lottie-animation
                  ref="anim"
                  :loop="true"
                  :autoPlay="true"
                  :animationData="require('@/assets/app.json')"
                  class="lotti"
                />
                <div class="texto">
                  <h5 class="text-h5 font-weight-semibold mb-1">
                    Bienvenidos a Yasu
                  </h5>
                  <p class="mb-0">Tu asistente Virtual</p>
                </div>
              </div>
            </v-card-text>

            <v-card-text style="padding-top: 0">
              <v-row class="justify-center">
                <v-col cols="12" md="10">
                  <v-form ref="form" action v-model="valid" lazy-validation>
                    <v-card-text style="padding-bottom: 5px">
                      <v-text-field
                        outlined
                        v-model="email"
                        :rules="nameRules"
                        label="Usuario"
                        prepend-inner-icon="mdi-account"
                        required
                        color="#f8b785"
                        rounded
                      ></v-text-field>

                      <v-text-field
                        outlined
                        color="#f8b785"
                        rounded
                        v-model="password"
                        :type="verContraseña ? 'text' : 'password'"
                        :rules="ReglasContraseña"
                        label="Contraseña"
                        prepend-inner-icon="mdi-key"
                        :append-icon="verContraseña ? 'mdi-eye' : 'mdi-eye-off'"
                        @click:append="verContraseña = !verContraseña"
                        required
                      ></v-text-field>
                      <div class="d-flex justify-space-between">
                        <v-checkbox
                          class="check"
                          v-model="ex4"
                          label="Recordarme"
                          color="#f8b785"
                          hide-details
                        ></v-checkbox>
                        <a href="#" style="padding-top: 3px">
                          Olvido su contraseña?
                        </a>
                      </div>
                      <v-btn
                        :loading="loading"
                        :disabled="loading"
                        block
                        rounded
                        class="white--text mt-5"
                        color="#f8b785"
                        @click="$router.push('/Home')"
                      >
                        iniciar sesion
                      </v-btn>
                    </v-card-text>

                    <v-card-text class="d-flex justify-space-around">
                      <span>Nuevo en nuestra plataforma?</span>
                      <a href="#"> Crea una Cuenta </a>
                    </v-card-text>

                    <v-card-text style="padding-top: 0">
                      <v-btn
                        rounded
                        color="#f8b785"
                        outlined
                        block
                        class="mb-2"
                      >
                        Conectate con Google
                        <v-icon right dark>mdi-google</v-icon></v-btn
                      >
                      <v-btn color="#f8b785" rounded outlined block>
                        Conectate con FaceBook
                        <v-icon right dark>mdi-facebook</v-icon></v-btn
                      >
                    </v-card-text>
                  </v-form>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import axios from "axios";
import LottieAnimation from "lottie-web-vue";

export default {
  components: {
    LottieAnimation,
  },
  data() {
    return {
      text: "error en login",
      timeout: 3000,
      dialogErr: false,
      loading: false,
      valid: false,
      verContraseña: false,
      password: "",
      email: "",
      nameRules: [
        (v) => !!v || "Ingresar Usuario",
        //(v) => v.length <= 10 || "El usuario debe contener minimo 10",
        // (v) =>  /(\d)/.test(v) || "debe tener un numero"
      ],
      ReglasContraseña: [
        (v) => !!v || "Ingresar contraseña",
        // (v) => v.length <= 4 || "La contraseña debe contener minimo 10",
        // (v) => /([A-Z])/.test(v)  || "Debe contener una mayuscula",
      ],
    };
  },
  methods: {
    async submit() {
      if (this.$refs.form.validate()) {
        console.log("validado");
        // let n=this.dni
        this.loading = true;

        try {
          await axios
            .post(
              `https://serviciogestor-production.up.railway.app/api/v1/auth/signin`,
              { email: `${this.email}`, password: `${this.password}` }
            )
            .then((res) => {
              // this.respuesta = res.data[0].estado;
              let respuesta = res.data.token;

              console.log("respuesta>>>>>", respuesta);
              this.email = "";
              this.password = "";
              this.token = res.data.token;
              localStorage.setItem("token", this.token);
              this.$store.dispatch("login");
              // this.$store.dispatch("logueo", respuesta.Loguear);
              // this.$store.dispatch("guardaDato", respuesta);

              // if(!respuesta.Loguear){
              //   this.$router.push('/about').catch(()=>{});
              // }else{
              //   this.dialogErr=true
              //   this.dni=""
              //   this.credencial=""

              // }
            });
        } catch (e) {
          console.log(e);
          this.dialogErr = true;
          this.email = "";
          this.password = "";
        } finally {
          this.loading = false;
        }
      }
    },
  },
};
</script>

<style>
.contenedor {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.content-lotti {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.lotti {
  max-width: 300px;
}
.texto {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.check {
  margin-top: 0 !important;
}
a {
  text-decoration: none;
  color: #f8b785 !important;
  /* padding-top: 3px; */
}
</style>
