<template>
  <v-app>
    <!-- <v-app-bar
      app
      color="primary"
      dark
    >
    
    </v-app-bar> -->

    <v-main class="fondo">
      <router-view/>
    </v-main>
  </v-app>
</template>

<script>

export default {
  name: 'App',

  data: () => ({
    //
  }),
};
</script>
<style>
.fondo{
  /* background:; */
  background:#f7b785;
  background-image: url("./assets/fondo.png");
  background-repeat: no-repeat;
  background-position: center center;
  background-size:cover;

}
</style>
